import React, { useEffect, useState } from "react";
import Wrapper from "./Wrapper";
import classnames from "classnames";
import { customisable } from "../config";
import useSize from "../hooks/useSize";

const CustomisableItem = () => {
    const [size] = useSize();
    const [active] = useState("custom");
    const [customTrigger, setCustomTrigger] = useState(false);

    const handleScroll = () => {
        const screen = window.innerHeight;
        const top = window.scrollY;

        const $customCar = document.getElementsByClassName("customisable-item")[0];

        const customCarOffsetTop = $customCar.offsetTop || $customCar.documentElement.clientHeight || 0;

        if (screen - (customCarOffsetTop - top) >= screen * 0.6) { //40% of the screen from the bottom
            setCustomTrigger(true);
        }

        if (top + screen <= customCarOffsetTop) {
            setCustomTrigger(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <Wrapper>
            <div className={classnames("customisable-item", { "customisable-item-s": size !== "l" })}>
                <div className="content">
                    <div className="desc">
                        <div className="title">
                            <p>Customisable</p>
                        </div>
                        {
                            customisable.desc.map(item=>(
                                <p key={item}>{item}</p>
                            ))
                        }
                    </div>
                    <div
                        className="customisable-img-wrapper">

                        <div className="customisable-img">
                            <div className={classnames("before-img", { "frame-box-animate-before": customTrigger })} />
                            <div className={classnames("after-img", { "frame-box-animate-after": customTrigger })} />
                        </div>
                        {
                            <div className="icon-list">
                                {
                                    Object.entries(customisable.iconList).map(([name, icon], i) => {
                                        return (
                                            <div
                                                key={name}
                                                className={classnames("icon-item", { [`icon-item-animate-${i}`]: customTrigger })}>
                                                <div className="icon">{icon}</div>
                                                <div className="name">{name}</div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </Wrapper>

    );
};

export default CustomisableItem;