import React, { useCallback } from "react";
import axios from "axios";
import toast from "cogo-toast";
import { useForm } from "react-hook-form";
import { debounce } from "../utils";

const url = "/contact-us/";

const info = {
    loading: "Sending...",
    success: "Thank you for your message!",
    fail: "Sorry, something went wrong. Please try again.",
};

const ContactForm = () => {

        const { register, handleSubmit, errors } = useForm();

        const onSubmit = useCallback(debounce(data => {

            let { hide } = toast.loading(info.loading);
            axios.post(url, data).then(() => {
                hide();
                toast.success(info.success);
                document.getElementsByClassName("contact-form-wrapper")[0].reset();
            }).catch(() => {
                hide();
                toast.error(info.fail);
                document.getElementsByClassName("contact-form-wrapper")[0].reset();
            });

        }), []);

        return (
            <form className="contact-form-wrapper" onSubmit={handleSubmit(onSubmit)}>
                <div className="input-wrapper">
                    <input
                        placeholder="Name"
                        className="contact-input"
                        type="text"
                        name="name"
                        ref={register({ required: true })}
                    />
                    {
                        errors.name && <p className="contact-error-msg">Please enter your name</p>
                    }
                </div>
                <div className="input-wrapper">
                    <input
                        placeholder="Company"
                        className="contact-input"
                        type="text"
                        name="company"
                        ref={register({ required: true })}
                    />
                    {
                        errors.company && <p className="contact-error-msg">Please enter your company name</p>
                    }
                </div>
                <div className="input-wrapper">
                    <input
                        placeholder="Phone"
                        className="contact-input"
                        type="text"
                        name="mobile"
                        ref={register({ required: true, pattern: /^\d+$/ })}
                    />
                    {
                        errors.mobile && <p className="contact-error-msg">Please enter a valid phone number</p>
                    }
                </div>
                <div className="input-wrapper">
                    <input
                        placeholder="Email"
                        className="contact-input"
                        type="text"
                        name="email"
                        ref={register({ required: true, pattern: /\S+@\S+\.\S+/ })}
                    />
                    {
                        errors.email && <p className="contact-error-msg">Please enter a valid email address</p>
                    }
                </div>
                <div className="input-wrapper">
                    <input
                        placeholder="Message"
                        className="contact-input"
                        name="message"
                        ref={register({ required: true })}
                    />
                    {
                        errors.message && <p className="contact-error-msg">Please leave a message</p>
                    }
                </div>
                <div className="contact-btn-wrapper">
                    <input
                        className="contact-btn"
                        type="submit"
                        value="Submit"
                    />
                </div>
            </form>
        );
    }
;

export default ContactForm;
