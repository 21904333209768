import React, { useState } from "react";
import Wrapper from "./Wrapper";
import PointOrigin from "./FeaturesCallOut";
import { features } from "../config";
import useSize from "../hooks/useSize";
import classnames from "classnames";
import ReactPlayer from "react-player";
import video from "../static/Videos/features.mp4"

const FeaturesItem = () => {
    const [activeOrigin, setActiveOrigin] = useState("gps");
    const { desc } = features;
    const [size] = useSize();

    const handleHoverOrigin = name => () => {
        setActiveOrigin(name);
    };

    return (
        <Wrapper>
            <div className={classnames("features-section", { "features-section-s": size !== "l" })}>
                <p className="title">Features</p>
                <div className="features-body">
                    <div className="features-video-wrapper">
                        <ReactPlayer url = {video}
                            width={size === 'l' ? 560 : 430}
                            height={size === 'l' ? 560 : 430}
                            playing={true}
                            loop={true}
                            muted={true}
                            playsinline={true}
                        />
                    </div>
                    <div className={classnames("call-outs-wrapper", { "call-outs-wrapper-s": size !== "l" })}>
                        <PointOrigin
                            className="origin-gps"
                            place="gps"
                            desc={desc.gps}
                            onMouseEnter={handleHoverOrigin("gps")}
                            show={activeOrigin === "gps"}
                            sourceAnchor={size === 'l' ? "left" : "top"}
                            targetAnchor={"bottom"}
                        />
                        <PointOrigin
                            className="origin-front-camera"
                            place="frontCamera"
                            desc={desc.frontCamera}
                            onMouseEnter={handleHoverOrigin("frontCamera")}
                            show={activeOrigin === "frontCamera"}
                            sourceAnchor={size === 'l' ? "right" : "top"}
                            targetAnchor={"bottom"}
                        />
                        <PointOrigin
                            className="origin-front-left-wheel"
                            place="frontLeftWheel"
                            desc={desc.frontLeftWheel}
                            onMouseEnter={handleHoverOrigin("frontLeftWheel")}
                            show={activeOrigin === "frontLeftWheel"}
                            sourceAnchor={size === 'l' ? "right" : "top"}
                            targetAnchor={size === 'l' ? "top" : "bottom"}
                        />
                        <PointOrigin
                            className="origin-battery-pack"
                            place="batteryPack"
                            desc={desc.batteryPack}
                            onMouseEnter={handleHoverOrigin("batteryPack")}
                            show={activeOrigin === "batteryPack"}
                            sourceAnchor={size === 'l' ? "left" : "top"}
                            targetAnchor={"bottom"}
                        />
                        <PointOrigin
                            className="origin-front-right-wheel"
                            place="frontRightWheel"
                            desc={desc.frontRightWheel}
                            onMouseEnter={handleHoverOrigin("frontRightWheel")}
                            show={activeOrigin === "frontRightWheel"}
                            sourceAnchor={size === 'l' ? "left" : "top"}
                            targetAnchor={size === 'l' ? "top" : "bottom"}
                        />
                        <PointOrigin
                            className="origin-lift-arm"
                            place="liftArm"
                            desc={desc.liftArm}
                            onMouseEnter={handleHoverOrigin("liftArm")}
                            show={activeOrigin === "liftArm"}
                            sourceAnchor={size === 'l' ? "right" : "top"}
                            targetAnchor={size === 'l' ? "top" : "bottom"}
                        />
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default FeaturesItem;