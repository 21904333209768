import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import useSize from "../hooks/useSize";

const IconsImgItem = props => {
    const [size] = useSize();

    return (
        <div className={classnames("icons-img", props.className, { "icons-img-s": size !== "l" })}>
            <div className="icons-list">
                <div className="title">{props.title}</div>
                <div className="icons-group">
                    {
                        Object.entries(props.iconList).map(([name, icon], i) => {
                            return (
                                <div
                                    className={classnames("icon-item opacity", { [`icon-item-animate-${i}`]: props.animateTrigger })}
                                    key={name}>
                                    <span className="icon">{icon}</span>
                                    <span className="text">{name}</span>
                                </div>
                            );
                        })
                    }
                </div>

            </div>
            <div className={classnames("img", props.imgClassName)} />
        </div>
    );
};

export default IconsImgItem;

IconsImgItem.propTypes = {
    title: PropTypes.string.isRequired,
    imgClassName: PropTypes.string.isRequired,
    iconList: PropTypes.object.isRequired,
    animateTrigger: PropTypes.bool,
};