import React, { useEffect, useState } from "react";
import classnames from "classnames";
import useSize from "../hooks/useSize";
import { debounce } from "../utils";

const data = [{
    buttonName: "Data-collection",
    imgClassName: "data-collection",
    screenTop: "-75%",
    screenLeft: "-108%",
    scale: "scale(.55)",
    screenTop2: "-86%",
    screenLeft2: "-200%",
    scale2: "scale(.6)",
    screenTop3: "-95%",
    screenLeft3: "-288%",
    scale3: "scale(.5)",
}, {
    buttonName: "Precision-spraying",
    imgClassName: "precision-spraying",
    screenTop: "-76%",
    screenLeft: "-106%",
    scale: "scale(.55)",
    screenTop2: "-87%",
    screenLeft2: "-194%",
    scale2: "scale(.6)",
    screenTop3: "-99%",
    screenLeft3: "-274%",
    scale3: "scale(.50)",
}, {
    buttonName: "Golf-ball-collection",
    imgClassName: "golf-ball-collection",
    screenTop: "-79%",
    screenLeft: "-102%",
    scale: "scale(.55)",
    screenTop2: "-92%",
    screenLeft2: "-185%",
    scale2: "scale(.6)",
    screenTop3: "-99%",
    screenLeft3: "-270%",
    scale3: "scale(.45)",
}, {
    buttonName: "Mowing",
    imgClassName: "mowing",
    screenTop: "-64.5%",
    screenLeft: "-123.6%",
    scale: "scale(.55)",
    screenTop2: "-74.6%",
    screenLeft2: "-224%",
    scale2: "scale(.46)",
    screenTop3: "-87%",
    screenLeft3: "-310%",
    scale3: "scale(.34)",
}, {
    buttonName: "Towing",
    imgClassName: "towing",
    screenTop: "-62%",
    screenLeft: "-127%",
    scale: "scale(.55)",
    screenTop2: "-73%",
    screenLeft2: "-228%",
    scale2: "scale(.46)",
    screenTop3: "-86%",
    screenLeft3: "-314%",
    scale3: "scale(.32)",
}, {
    buttonName: "more",
    imgClassName: "more",
    screenTop: "-75%",
    screenLeft: "-108%",
    scale: "scale(.6)",
    screenTop2: "-86%",
    screenLeft2: "-200%",
    scale2: "scale(.6)",
    screenTop3: "-95%",
    screenLeft3: "-288%",
    scale3: "scale(.5)",
}];

const Attachments = (props) => {
    const [size] = useSize();
    const [buttonIndex, setButtonIndex] = useState(-1);
    const [lastButtonIndex, setLastButtonIndex] = useState(-1)
    const CONTACT_FORM = 5;
    let previousWidth = window.innerWidth;
    let timer;

    const handleResize = debounce((e) => {
        const width = e.target.innerWidth;
        if (width !== previousWidth) {
            setButtonIndex(-1);
            previousWidth = width;
        }

        clearTimeout(timer);
        const $img = document.getElementsByClassName("img")[0];
        $img.style.transition = "none";

        timer = setTimeout(() => {
            $img.style.transition = "0.6s ease-out";
        }, 1000);

    }, 10);

    useEffect(() => {
        if (buttonIndex === -1) {
            setTimeout(() => {
                setButtonIndex(0);
            }, 800);
        }
    }, [buttonIndex]);

    useEffect(() => {
        if (buttonIndex > -1 && buttonIndex < data.length) {
            const $img = document.getElementsByClassName("img")[0];
            const style = data[buttonIndex];

            if (size === "l") {
                $img.style.top = style.screenTop;
                $img.style.left = style.screenLeft;
                $img.style.transform = style.scale;
            }
            if (size === "m") {
                $img.style.top = style.screenTop2;
                $img.style.left = style.screenLeft2;
                $img.style.transform = style.scale2;
            }
            if (size === "s") {
                $img.style.top = style.screenTop3;
                $img.style.left = style.screenLeft3;
                $img.style.transform = style.scale3;
            }
        }
    }, [buttonIndex]);

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleButtonClick = (key) => {
        if (key !== buttonIndex) {
          setLastButtonIndex(buttonIndex);
          setButtonIndex(key);
        }
      };

    return (
        <div
            className={classnames(
                "attachments",
                { "attachments-m": size === "m" },
                { "attachments-s": size === "s" },
                { "attachments-contact-form": buttonIndex === CONTACT_FORM },
                { "attachments-contact-form-m": size === "m" && buttonIndex === CONTACT_FORM },
                { "attachments-contact-form-s": size === "s" && buttonIndex === CONTACT_FORM }
            )}
        >
            <div className="title">Multiple Functions. One Robot.</div>
            <div className="button-list">
                {
                    data.map((item, key) => (
                        <button key={key} className={classnames({ "button-active": key === buttonIndex })}
                            onClick={() => handleButtonClick(key)}>{item.buttonName}</button>
                    ))
                }
            </div>
            <div className={classnames("img-wrapper", {
                "img-wrapper-m": size === "m",
                "img-wrapper-s": size === "s",
                "img-wrapper-contact-form": buttonIndex === CONTACT_FORM,
            })}>
                <div className="img">
                    {
                        data.map((item, key) => (
                            <div key={key} className={classnames("img-prop", [item.imgClassName], {
                                "fade-in": key === buttonIndex,
                                "fade-out": key === lastButtonIndex && buttonIndex !== 5,
                            })} />
                        ))
                    }
                </div>
                {
                    <div
                        className={classnames("contact-modal", { "contact-modal-s": size !== "l" }, { "unhide": buttonIndex === 5 })}>
                        <div className="contact-form">
                            <div className="desc">
                                <h1>Have some ideas in mind?</h1>
                                <p>Get in touch to discuss how Grover may make your day easier or if you have any attachments
                                    you wish to integrate onto this autonomous platform.</p>
                            </div>
                            {props.children}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Attachments;