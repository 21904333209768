import React from "react";
import { contact } from "../config";
import ContactForm from "./ContactForm";
import Wrapper from "./Wrapper";
import classnames from "classnames";
import useSize from "../hooks/useSize";

const Contact = () => {
    const [size] = useSize();

    return (
        <Wrapper>
            <div className={classnames("contact", { "contact-s": size !== "l" })}>
                <div className="contact-content">
                    <div className="title">
                        {contact.title.map(item => {
                            return <p key={item}>{item}</p>;
                        })}
                    </div>
                    <div className="desc">{contact.desc}</div>
                </div>
                <ContactForm />
            </div>
        </Wrapper>
    );
};

export default Contact;