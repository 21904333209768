import React, { useEffect, useState } from "react";
import Wrapper from "./Wrapper";
import classnames from "classnames";
import { navMode } from "../config";
import useSize from "../hooks/useSize";

const NavMode = () => {
    const [size] = useSize();
    const [customTrigger, setCustomTrigger] = useState(false);

    const handleScroll = () => {
        const screen = window.innerHeight;
        const top = window.scrollY;

        const $customCar = document.getElementsByClassName("nav-modes")[0];

        const customCarOffsetTop = $customCar.offsetTop || $customCar.documentElement.clientHeight || 0;

        if (screen - (customCarOffsetTop - top) >= screen * 0.6) { //40% of the screen from the bottom
            setCustomTrigger(true);
        }

        if (top + screen <= customCarOffsetTop) {
            setCustomTrigger(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <Wrapper>
            <div className={classnames("nav-modes", { "nav-modes-s": size !== "l" })}>
                <div className="content">
                    <div className="desc">
                        <div className="title">
                            <p>Multi-Nav modes</p>
                        </div>
                        {
                            navMode.desc.map(item => (
                                <p key={item}>{item}</p>
                            ))
                        }
                    <div className="nav-mode-list">
                        {
                            navMode.list.map(item => (
                                <p key={item}>{item}</p>
                            ))
                        }
                    </div>
                    </div>
                    <div
                        className="nav-img-wrapper">
                        <div className="nav-img" />
                    </div>
                </div>
            </div>
        </Wrapper>

    );
};

export default NavMode;