import React from "react";
import LandingFeatureIconList from "./LandingFeatureIconList";
import useSize from "../hooks/useSize";
import classnames from "classnames";
import LandingMsg from "./LandingMsg";

const Landing = () => {
    const [size] = useSize();

    return (
        <div className={classnames("landing", { "landing-s": size !== "l" })}>
            <div className={classnames("landing-pic", { "landing-pic-s": size !== "l" })} />
            <div className="content-wrapper">
                <div className={classnames("content", { "content-s": size !== "l" })}>
                    <LandingMsg />
                </div>
            </div>
            <LandingFeatureIconList />
        </div>
    );
};

export default Landing;