import React from "react";
import { landing } from "../config";
import useSize from "../hooks/useSize";
import classnames from "classnames";

const LandingFeatureIconList = () => {
    const [size] = useSize();

    return (
        <div className="landing-feature-icon-list">
            <div
                className={classnames("landing-feature-icon-list-wrapper", { "landing-feature-icon-list-wrapper-s icon-item-animate-0": size !== "l" })}>
                {
                    Object.entries(landing.featureIconList).map(([name, Element]) => {
                        return (
                            <div key={name} className="feature-icon">
                                <span>{Element}</span>
                                <span>{name}</span>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default LandingFeatureIconList;