import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import Logo from "-!svg-react-loader?name=footerLogo!../static/peek_robotics_logo_white.svg";
import { footer } from "../config";
import Wrapper from "./Wrapper";
import useSize from "../hooks/useSize";
import classnames from "classnames";

const Footer = () => {
    const { address, phone } = footer;
    const [size] = useSize();

    const linkedInUrl = "https://www.linkedin.com/company/peek-robotics";

    const handleClickLinkedIn = () => {
        window.open(linkedInUrl, "_blank");
    };

    return (
        <div className={classnames("footer", { "footer-s": size !== "l" })}>
            <Wrapper>
                <div className="footer-content">
                    <div className="footer-logo">
                        <Logo className="footer-logo-img" />
                        <p>©2024 Peek Robotics. All rights reserved.</p>
                    </div>
                    <div className="footer-info">
                        <div className="footer-address">
                            <p>Address</p>
                            {
                                address.map(item => {
                                    return (
                                        <span className="footer-address-item" key={item}>{item}</span>
                                    );
                                })
                            }
                        </div>
                        <div className="footer-phone-follow">
                            <div>
                                <p>Phone</p>
                                <span>{phone}</span>
                            </div>
                            <div>
                                <p>Follow us</p>
                                <img
                                    className="footer-follow-img"
                                    onClick={handleClickLinkedIn}
                                    src={require("../static/linkedin-logo.svg")}
                                    alt="linkedIn"
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </Wrapper>
        </div>
    );
};


export default Footer;