import React, { useState } from "react";
import { landing } from "../config";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
// eslint-disable-next-line import/no-webpack-loader-syntax
import IconPlayBack from "-!svg-react-loader?name=icon!../static/play-button.svg";

const LandingMsg = () => {
    const [isOpen, setOpen] = useState(false);

    return (
        <div className="landing-msg">
            <div className="desc icon-item-animate-0">
                {
                    landing.desc.map(item => {
                        return <p key={item}>{item}</p>;
                    })
                }
                {/* <div className="play-video-btn-wrapper">
                    <button className="play-video-btn" onClick={() => setOpen(true)}>
                        <span>Watch Video</span>
                        <span><IconPlayBack /></span>
                    </button>
                </div> */}
            </div>
            <ModalVideo
                channel="youtube"
                youtube={{
                    autoplay: 1,
                    mute: 1,
                }}
                isOpen={isOpen}
                videoId="c6U3UFcR9mg"
                onClose={() => setOpen(false)}
            />
        </div>
    );
};

export default LandingMsg;