import React from "react";
import classnames from "classnames";
import useSize from "../hooks/useSize";
// eslint-disable-next-line import/no-webpack-loader-syntax
import Logo from "-!svg-react-loader?name=logo!../static/PEEK_peek-logo-red-w-white-txt.svg";

const Header = () => {
    const [size] = useSize();
    return (
        <div className={classnames("header", { "header-s": size !== "l" })}>
            <div className="content-wrapper">
                <Logo className="logo" />
            </div>
        </div>
    );
};

export default Header;