import React, { useEffect, useState } from "react";
import { applications } from "../config";
import Wrapper from "./Wrapper";
import IconsImgItem from "./IconsImgItem";
import classnames from "classnames";
import useSize from "../hooks/useSize";

const ApplicationsItem = () => {
    const [size] = useSize();
    const [orchardTrigger, setOrchardTrigger] = useState(false);
    const [golfTrigger, setGolfTrigger] = useState(false);
    const [viticultureTrigger, setViticultureTrigger] = useState(false);

    const handleScroll = () => {
        const screen = window.innerHeight;
        const top = window.scrollY;
        const el = document.getElementsByClassName("application-section")[0];
        const height = el.clientHeight;
        const target = el.offsetTop || el.documentElement.clientHeight || 0;

        if (screen - (target + height - top) > 20) {
            setOrchardTrigger(true);
        }

        if (screen - (target + height * 2 - top) > 20) {
            setGolfTrigger(true);
        }

        if (screen - (target + height * 3 - top) > 20) {
            setViticultureTrigger(true);
        }

        if ((top + screen - target) <= 0) {
            setViticultureTrigger(false);
            setGolfTrigger(false);
            setOrchardTrigger(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const getTrigger = name => {
        switch (name.toLowerCase()) {
            case "orchard":
                return orchardTrigger;
            case "golfcourse":
                return golfTrigger;
            case "viticulture":
                return viticultureTrigger;
            default:
                return false;
        }
    };

    return (
        <div className={classnames("applications-item", { "applications-item-s": size !== "l" })}>
            <div className="applications-landing">
                <Wrapper>
                    <p className="title">
                        Applications
                    </p>
                    <div
                        className={classnames({
                            "application-desc": size === "l",
                            "application-desc-s": size !== "l",
                        })}
                    >{applications.desc}</div>
                </Wrapper>
            </div>
            {
                Object.entries(applications.iconList).map(([name, iconList], i) => {
                    const bgColor = i % 2 ? "#fafafa" : "#FFFFFF";
                    const className = size === "l" ? { "reverse": i % 2 } : "column-reverse";
                    const type = name.replace(/\s/, ``).toLowerCase();
                    const trigger = getTrigger(type);
                    return (
                        <div className="application-section" key={name} style={{ backgroundColor: bgColor }}>
                            <Wrapper>
                                <IconsImgItem
                                    animateTrigger={trigger}
                                    className={classnames(className)}
                                    imgClassName={type}
                                    title={name}
                                    iconList={iconList}
                                />
                            </Wrapper>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default ApplicationsItem;