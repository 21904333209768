import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { ArcherContainer, ArcherElement } from "react-archer"

const PointOrigin = props => {
    const handleMouseEnter = () => {
        props.onMouseEnter();
    };

    return (
        <div className={classnames("call-out", props.className)}>
            <ArcherContainer
                svgContainerStyle={{
                    opacity: props.show ? "1" : "0",
                    display: props.show ? "block" : "none",
                    zIndex: 1,
                    overflow: 'visible'
                }}
            >
                <ArcherElement id="desc">
                    <div
                        className={classnames(
                            "call-out-desc",
                            { "call-out-desc-active": props.show },
                            {
                                "gps-desc": props.place === "gps",
                                "front-camera-desc": props.place === "frontCamera",
                                "front-left-wheel-desc": props.place === "frontLeftWheel",
                                "battery-pack-desc": props.place === "batteryPack",
                                "lift-arm-desc": props.place === "liftArm",
                                "front-right-wheel-desc": props.place === "frontRightWheel",
                                "show": props.show,
                            },
                        )}
                    >
                        {props.desc}
                    </div>
                </ArcherElement>

                <ArcherElement
                    id="lineBreak"
                    relations={[
                        {
                            targetId: 'desc',
                            sourceAnchor: "top",
                            targetAnchor: props.targetAnchor,
                            style: { strokeColor: '#b1b1b1', strokeWidth: 1, lineStyle:"straight", endMarker: false },
                        },
                    ]}
                >
                    <div
                        className={classnames(
                            "call-out-mid-pt",
                            {
                                "gps-mid-pt": props.place === "gps",
                                "front-camera-mid-pt": props.place === "frontCamera",
                                "front-left-wheel-mid-pt": props.place === "frontLeftWheel",
                                "battery-pack-mid-pt": props.place === "batteryPack",
                                "lift-arm-mid-pt": props.place === "liftArm",
                                "front-right-wheel-mid-pt": props.place === "frontRightWheel",
                            },
                        )}
                    />
                </ArcherElement>

                <ArcherElement
                    id="dot"
                    relations={[
                        {
                            targetId: 'lineBreak',
                            sourceAnchor: props.sourceAnchor,
                            targetAnchor: "top",
                            style: { strokeColor: '#b1b1b1', strokeWidth: 1, lineStyle:"straight", endMarker: false },
                        },
                    ]}
                >
                    <div className={classnames("call-out-origin", { "call-out-origin-active": props.show, })}
                        onMouseEnter={handleMouseEnter}
                    />
                </ArcherElement>
            </ArcherContainer >
        </div >
    );
};

export default PointOrigin;

PointOrigin.propTypes = {
    place: PropTypes.oneOf([
        "gps", "frontCamera", "frontLeftWheel", "frontLeftWheel", "batteryPack", "liftArm", "frontRightWheel",
    ]).isRequired,
    className: PropTypes.string,
    id: PropTypes.string,
    targetAnchor: PropTypes.string,
    sourceAnchor: PropTypes.string
};