/* eslint-disable */
import React from "react";
import IconAllElectric from "-!svg-react-loader?name=icon!../static/icon-all-electric.svg";
import IconAutoNav from "-!svg-react-loader?name=icon!../static/icon-autonomous.svg";
import IconOutdoor from "-!svg-react-loader?name=icon!../static/icon-outdoor.svg";
import IconMultiFunction from "-!svg-react-loader?name=icon!../static/icon-multi-function.svg";
import IconMower from "-!svg-react-loader?name=icon!../static/icon-mower.svg";
import IconCarrier from "-!svg-react-loader?name=icon!../static/icon-carrier.svg";
import IconRoboticarms from "-!svg-react-loader?name=icon!../static/icon-roboticarms.svg";
import IconCollector from "-!svg-react-loader?name=icon!../static/icon-collector.svg";
import IconMonitor from "-!svg-react-loader?name=icon!../static/icon-monitor.svg";
import IconMow from "-!svg-react-loader?name=icon!../static/icon-mow.svg";
import IconSpray from "-!svg-react-loader?name=icon!../static/icon-spray.svg";
import IconReport from "-!svg-react-loader?name=icon!../static/icon-report.svg";
import IconRecognise from "-!svg-react-loader?name=icon!../static/icon-recognise.svg";
import IconRemove from "-!svg-react-loader?name=icon!../static/icon-weed.svg";
import IconReplant from "-!svg-react-loader?name=icon!../static/icon-replant.svg";
import IconMaintain from "-!svg-react-loader?name=icon!../static/icon-maintain.svg";
import IconFertilise from "-!svg-react-loader?name=icon!../static/icon-fertilise.svg";
import IconRecord from "-!svg-react-loader?name=icon!../static/icon-record.svg";

export const landing = {
    desc: [
        ` Do More with Grover`,
    ],
    featureIconList: {
        "All Electric": <IconAllElectric />,
        "Autonomous Navigation": <IconAutoNav />,
        "Multi-function": <IconMultiFunction />,
        "Indoor/Outdoor": <IconOutdoor />,
    },
};

export const attachments = {
    buttonList: ["Data Collecting", "Mowing", "Precision Spraying", "Golf Ball Collecting", "Towing", "+ more"],
};

export const dividerBarDesc = "Grover is an adaptable robotics platform that can perform multiple tasks";

export const features = {
    desc: {
        gps: "High precision GPS ensures Grover stays on the planned paths.",
        frontCamera: "Front and back stereo cameras enable Grover to operate safely and accurately.",
        batteryPack: "Quick-replace battery packs enable Grover to operate for hours whilst battery packs can recharge independently.",
        frontLeftWheel: "4WD gives it precise and controlled navigation on a variety of terrain.",
        liftArm: "Motorised lift arms for mounting a wide range of tool attachments",
        frontRightWheel: "The wheels contain brushless DC motors to deliver smooth and controlled navigation.",
    },
};

export const navMode = {
    desc: [
        "Grover leverages a range of technologies to achieve resilient high-precision autonomous navigation.",
        "This includes GPS path planning, Lidar and vision processing for object detection as well as operating in confined spaces. A dedicated UI allows you to interact and monitor the robot’s activities effortlessly.",
        "Plot a path. Hitch a function. Go.",
    ],
    list: [
        "GPS",
        "LiDar",
        "Vision Detection",
        "“Follow me”",
        "Remote Control",
    ],
};

export const customisable = {
    desc: [
        "Grover’s functional arms can be fitted with a range of tools that allow it to perform different functions. Examples of this can be cutting shears for mowing or a linear rail that enables targeted drilling or spraying of weeds.",
        "Grover has been tested up to 100kgs of load carrying capacity. The chassis can be adapted to suit larger volumes if necessary.",
        "Equipped with a range of sensory inputs such as cameras, Lidar and ultrasonic sensors, Grover can acquire data for analysis both during and post mission.",
        "Grover can be equipped with a robotic arm to enable a diverse range of applications.",
    ],
    img: "customisable",
    iconList: {
        Mower: <IconMower />,
        Carrier: <IconCarrier />,
        "Robotic Arm": <IconRoboticarms />,
        Collector: <IconCollector />,
    },
};

export const applications = {
    desc: `The versatility of the platform make Grover a good candidate for a wide variety of functions. 
    Equipped with a range of sensors, Grover can navigate to acquire visual data for crop yield analysis as well carry 
    load from point A to point B and back. Operating on a sophisticated navigation stack, 
    Grover can be deployed efficiently and operate with autonomy as well as ensure the safety for any people or animals in vicinity. 
    Some intended applications are shown below:`,
    iconList: {
        Orchard: {
            Monitor: <IconMonitor />,
            Mow: <IconMow />,
            Spray: <IconSpray />,
            Report: <IconReport />,
        },
        "Golf Course": {
            Recognise: <IconRecognise />,
            Weed: <IconRemove />,
            Replant: <IconReplant />,
            Maintain: <IconMaintain />,
        },
        Viticulture: {
            Recognise: <IconRecognise />,
            Fertilise: <IconFertilise />,
            Mow: <IconMow />,
            Record: <IconRecord />,
        },
    },
};

export const contact = {
    title: ["Interested to learn more?", "Let’s connect."],
    desc: `Peek Robotics are always looking for opportunities to test Grover in the field.
     If a land-based rover platform has potential application to your workplace and could assist with your automation journey 
     then let’s connect.`,
};

export const footer = {
    address: [
        "Level 3 Home Ideas Centre",
        "10 Hutt Road",
        "Petone, Lower Hutt",
        " Wellington",
        "New Zealand 5012",
    ],
    phone: "+64 4 569 1262",
};

