import { useEffect, useState } from "react";

const useSize = () => {
    const [size, setSize] = useState(null);

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });

    const handleResize = () => {

        const w = window.innerWidth;
        if (w > 700) {
            setSize("l")
        } else if (w <= 700 && w > 500) {
            setSize("m");
        } else {
            setSize("s");
        }
    };

    return [size];
};

export default useSize;