import React from "react";
import "./css/App.scss";
import Header from "./components/Header";
import Landing from "./components/Landing";
import DividerBar from "./components/DividerBar";
import Features from "./components/Features";
import { dividerBarDesc } from "./config";
import NavMode from "./components/NavMode";
import Customisable from "./components/Customisable";
import ApplicationsItem from "./components/ApplicationsItem";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Attachments from "./components/Attachments";
import ContactForm from "./components/ContactForm";

function App () {

    return (
        <div className="App">
            <Header />
            <Landing />
            <DividerBar height={10} color="red" />
            <Attachments>
                <ContactForm />
            </Attachments>
            <DividerBar height={150}>
                <p
                    style={{
                        height: 150,
                        wordBreak: "break-word",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        padding: "0 20px",
                    }}
                >
                    {dividerBarDesc}
                </p>
            </DividerBar>
            <Features />
            <NavMode />
            <Customisable />
            <DividerBar height={10} color="redReverse" />
            <ApplicationsItem />
            <DividerBar height={10} color="redReverse" />
            <Contact />
            <Footer />
        </div>
    );
}

export default App;
