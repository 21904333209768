import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const DividerBar = props => {
    const { height, color } = props;

    const getColorClassName = () => {

        switch (color) {
            case "red":
                return "red-gradient";

            case "redReverse":
                return "red-gradient-reverse";

            case "grey":
                return "grey";

            case "black":
            default:
                return "black";
        }
    };

    return (
        <div className={classnames("divider-bar", getColorClassName())} style={{ height }}>
            {props.children}
        </div>
    );
};

export default DividerBar;

DividerBar.propTypes = {
    height: PropTypes.number.isRequired,
    color: PropTypes.string,
};